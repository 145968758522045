<template>
  <div>
    <v-app-bar app flat>
      <v-app-bar-nav-icon
        @click="miniDrawer = !miniDrawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>Cixxonia Printer</v-toolbar-title>
    </v-app-bar>
    <v-card>
      <v-navigation-drawer
        app
        class="pt-4"
        color="secondary"
        v-model="drawer"
        :mini-variant="miniDrawer"
        permanent
      >
        <v-list-item class="px-2">
          <v-list-item-avatar>
            <v-img v-if="miniDrawer" src="@/assets/images/simbolo.png"></v-img>
          </v-list-item-avatar>

          <v-list-item-title>
            <v-img src="@/assets/images/logoClaim.png"></v-img>
          </v-list-item-title>

          <v-btn icon @click.stop="miniDrawer = !miniDrawer">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item
            v-for="(item, i) in items"
            :to="{ name: item.route }"
            :key="`menu${i}`"
            active-class="highlighted"
            link
          >
            <v-list-item-icon class="mr-0">
              <img
                :src="isActive(item.route) ? item.activeIcon : item.icon"
                :width="item.size.width"
                :height="item.size.height"
              />
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="white--text ml-4">{{
                item.title
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <v-list>
            <v-list-item @click="exit" link>
              <v-list-item-icon class="me-6" :style="{ width: '24px' }">
                <v-btn icon>
                  <v-icon color="white">mdi-logout</v-icon>
                </v-btn>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="white--text">
                  Logout
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
    </v-card>
    <v-main>
      <v-toolbar
        color="primary"
        dense
        class="accent--text text-h5 mb-2"
        v-if="pageTitle"
        flat
      >
        {{ pageTitle }}
      </v-toolbar>
      <router-view></router-view>
    </v-main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "admin-layout",
  data() {
    return {
      drawer: true,
      items: [
        {
          title: "Printers",
          icon: require("@/assets/images/icon-printer-w.svg"),
          activeIcon: require("@/assets/images/icon-printer.svg"),
          route: "printers",
          size: { width: 32, height: 28 },
        },
        {
          title: "Printing Orders",
          icon: require("@/assets/images/orden-w.svg"),
          activeIcon: require("@/assets/images/orden-b.svg"),
          route: "order-print",
          size: { width: 34, height: 28 },
        },
      ],
      miniDrawer: true,
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    isActive(to) {
      return this.$router.currentRoute.path.includes(to);
    },
    exit() {
      this.logout();
      this.$router.push({ name: "login" });
    },
  },
  computed: {
    pageTitle() {
      return this.$route.meta?.title;
    },
  },
};
</script>

<style></style>
